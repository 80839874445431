<template>
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer"
				>Par&aacute;metros</a
			>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<router-link to="/productos" class="text-500 no-underline"
				>Productos</router-link
			>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<router-link to="/unidadmedidas" class="text-900 line-height-3"
				>Unidad de Medidas</router-link
			>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-12 lg:px-12">
		<Toast />
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">
					Administrar Unidades de Medidas <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge>
				</div>
			</div>
			<div class="flex mt-3 lg:mt-0">
				<Button
					label="Nuevo"
					v-if="'Unidad de Medida Crear' in auth.user.permissions"
					class="p-button-outlined mr-2 p-button-lg"
					icon="pi pi-plus"
					v-tooltip.top="'Nueva Unidad de Medida'"
					@click="openNuevo"
				></Button>
				<FileUpload
					mode="basic"
					v-if="'Unidad de Medida Importar' in auth.user.permissions"
					id="file"
					accept=".xlsx"
					name="file[]"
					type="file"
					enctype="multipart/form-data"
					:customUpload="true"
					@uploader="onChange"
					label="Importar"
					v-tooltip.top="'Importar Unidades de Medidas'"
					chooseLabel="Importar"
					class="p-button-outlined mr-2 p-button-lg"
				/>
				<Button
					label="Exportar"
					v-if="'Unidad de Medida Exportar' in auth.user.permissions"
					type="button"
					icon="pi pi-upload"
					class="p-button-outlined mr-2 p-button-lg"
					v-tooltip.top="'Exportar Unidades de Medidas'"
					@click="exportUnidadmedidas()"
				/>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="dtunidadmedidaes"
				:value="unidadmedidas"
				:loading="cargando"
				dataKey="id"
				:paginator="true"
				:rows="10"
				:filters="buscar"
				class="p-datatable-sm"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[5, 10, 25, 50, 100]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Unidad de Medidas"
				responsiveLayout="scroll"
				:rowHover="true"
			>
				<template #header>
					<div
						class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
					>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
							<i class="pi pi-search" />
							<InputText
								v-model="buscar['global'].value"
								placeholder="Buscar..."
							/>
						</span>
					</div>
				</template>
				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty>
					<span class="flex align-items-center justify-content-center p-invalid"
						>No existen Registros!</span
					></template
				>
				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>
				<Column field="id" header="CÓDIGO" :sortable="true">
					{{ data.id }}
				</Column>
				<Column field="nombre" header="NOMBRE">
					{{ data.nombre }}
				</Column>
				<Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado.value]">
              {{ data.estado.label }}
            </span>
          </template>
        </Column>
				<Column field="created_at" header="FECHA DE CREACIÓN">
					<template #body="{ data }">
						{{ formatDate(data.created_at) }}
					</template>
				</Column>
				<Column headerStyle="min-width:10rem;" header="ACCIONES">
					<template #body="slotProps">
						<Button
							class="p-button-icon-only p-button-raised"
							icon="pi pi-ellipsis-v"
							v-tooltip.top="'Ver Acciones'"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						/>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						>
						</Menu>
					</template>
				</Column>
			</DataTable>
		</div>

		<Dialog
			v-model:visible="deleteUnidadmedidaDialog"
			:style="{ width: '450px' }"
			header="Confirmar"
			:modal="true"
		>
			<div class="flex align-items-center justify-content-center">
				<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
				<span v-if="unidadmedida"
					>Estas Seguro que quieres eliminar la unidadmedida
					<b>{{ unidadmedida.nombre }}</b> ?</span
				>
			</div>
			<template #footer>
				<Button
					label="No"
					icon="pi pi-times"
					class="p-button-secondary"
					@click="deleteUnidadmedidaDialog = false"
					:loading="enviado"
				/>
				<Button
					label="Si"
					icon="pi pi-check"
					class="p-button-prymary"
					@click="deleteUnidadmedida"
					:loading="enviado"
				/>
			</template>
		</Dialog>

		<UnidadMedidaCreate
			:show="unidadmedidaDialog"
			:unidadmedida="unidadmedida"
			@closeModal="ocultarDialog"
			@actualizarListado="cargarUnidadesMedidas"
		></UnidadMedidaCreate>
	</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import UnidadmedidaService from "@/service/UnidadmedidaService";
import { useAuth } from "@/stores";
import UnidadMedidaCreate from "@/module/unidadmedida/UnidadMedidaCreate.vue";

export default {
	components: {
		UnidadMedidaCreate,
	},
	data() {
		return {
			unidadmedidas: null,
			cargando: true,
			unidadmedidaDialog: false,
			deleteUnidadmedidaDialog: false,
			unidadmedida: {},
			buscar: {},
			items: [],
			totalRegistros: 0,
			sucursalSelect: null,
      claseEstado: ["status-outofstock", "status-instock"],
		};
	},
	unidadmedidaService: null,
	auth: null,
	created() {
		this.auth = useAuth();
		this.unidadmedidaService = new UnidadmedidaService();
		this.initBuscar();
	},
	mounted() {
		this.cargarUnidadesMedidas();
	},
	methods: {
		acciones(datos) {
			let permisosUsuarioAuth = this.auth.user.permissions;
			return [
				{
					label: "Editar",
					disabled:
						"Unidad de Medida Editar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-pencil",
					command: () => {
						this.editUnidadmedida(datos);
					},
				},
				{
					label: "Eliminar",
					disabled:
						"Unidad de Medida Eliminar" in permisosUsuarioAuth ? false : true,
					icon: "pi pi-trash",
					command: () => {
						this.confirmDeleteUnidadmedida(datos);
					},
				},
			];
		},
		cargarUnidadesMedidas() {
			this.unidadmedidaService.getUnidadmedidasAll().then((data) => {
				this.unidadmedidas = data;
				this.cargando = false;

				this.unidadmedidas.forEach((unidadmedida) => {
					unidadmedida.created_at = new Date(unidadmedida.created_at);
					unidadmedida.updated_at = new Date(unidadmedida.updated_at);
					unidadmedida.estado = {
						label: this.textoEstado(unidadmedida.estado),
						value: unidadmedida.estado,
					};
				});
			});
		},
		openNuevo() {
			this.unidadmedida = {
				estado: { label: "Activo", value: 1 },
			};
			this.unidadmedidaDialog = true;
		},
		ocultarDialog() {
			this.unidadmedidaDialog = false;
			this.unidadmedida = {};
		},
		onChange(event) {
			let archivo = event.files[0];
			let formData = new FormData();
			formData.append("miarchivo", archivo);
			formData.append("sucursal_para", this.sucursalSelect);
			this.unidadmedidaService
				.importUnidadmedidaNueva(formData)
				.then((data) => {
					this.cargando = true;
					this.cargarUnidadesMedidas();
					this.$toast.add({
						severity: "info",
						summary: "Success",
						detail: data.mensaje,
						life: 3000,
					});
				});
		},
		exportUnidadmedidas() {
			this.unidadmedidaService.exportUnidadmedidasAll().then(() => {});
		},
		textoEstado(estado) {
			return estado == 1 ? "Activo" : "Inactivo";
		},
		editUnidadmedida(unidadmedida) {
			this.unidadmedida = { ...unidadmedida };
			this.unidadmedidaDialog = true;
		},
		confirmDeleteUnidadmedida(unidadmedida) {
			this.unidadmedida = { ...unidadmedida };
			this.deleteUnidadmedidaDialog = true;
		},
		deleteUnidadmedida() {
			this.unidadmedidaService
				.deleteUnidadmedida(this.unidadmedida)
				.then((data) => {
					this.unidadmedidas = this.unidadmedidas.filter(
						(val) => val.id !== this.unidadmedida.id
					);
					this.deleteUnidadmedidaDialog = false;
					this.unidadmedida = {};
					this.$toast.add({
						severity: "success",
						summary: "Exito",
						detail: data.mensaje,
						life: 3000,
					});
				});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.unidadmedidas.length; i++) {
				if (this.unidadmedidas[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		initBuscar() {
			this.buscar = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			};
		},
		formatDate(value) {
			return value.toLocaleDateString("es-ES", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
				hour: "numeric",
				minute: "numeric",
			});
		},
	},
	watch: {
		unidadmedidas() {
			this.totalRegistros = this.unidadmedidas.length;
		},
	},
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
</style>
